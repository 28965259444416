import React from 'react'
import availableLangs from '@localization/availableLangs'
import PricingPageContainer from '@containers/pagesContainers/PricingPageContainer'
import SeoContainer from '@containers/SeoContainer'

const IndexPage = () => {
  return (
    <>
      <SeoContainer pageLang={availableLangs.de} pagePath='pricing' />
      <PricingPageContainer />
    </>
  )
}

export default IndexPage
